<template>
  <el-drawer
    size="900px"
    :visible.sync="visible"
    :wrapperClosable="false"
    :show-close="false"
    :withHeader="false"
  >
    <div class="editResume">
      <el-container>
        <el-header height="auto">
          <fw-header :titles="titles" />
        </el-header>
        <el-main>
          <el-form
            size="small"
            class="form-box"
            ref="form"
            label-position="left"
            label-width="150px"
            :rules="formRules"
            :model="form"
          >
            <fw-title style="margin-top:-20px" titles="基本信息"></fw-title>
            <div class="form-padding">
              <el-form-item label="姓名" prop="name">
                <el-input
                  placeholder="请输入姓名"
                  class="form-input"
                  v-model="form.name"
                  maxlength="32"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="gender">
                <el-radio-group v-model="form.gender">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="0">女</el-radio>
                  <!-- <el-radio :label="2">第三性</el-radio> -->
                </el-radio-group>
              </el-form-item>
              <el-form-item label="联系方式" prop="mobile">
                <el-input
                  placeholder="请输入11位手机号码"
                  class="form-input"
                  v-model="form.mobile"
                  maxlength="11"
                  show-word-limit
                  :disabled="disabledM"
                ></el-input>
              </el-form-item>
              <el-form-item label="出生日期" prop="birth_date">
                <el-date-picker
                  placeholder="请选择出生日期"
                  value-format="yyyy-MM-dd"
                  class="form-input"
                  v-model="form.birth_date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="毕业学校" prop="graduate_school">
                <el-input
                  placeholder="请输入毕业学校"
                  class="form-input"
                  v-model="form.graduate_school"
                  maxlength="11"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="毕业日期" prop="graduate_date">
                <el-date-picker
                  placeholder="请选择毕业日期"
                  value-format="yyyy-MM-dd"
                  class="form-input"
                  v-model="form.graduate_date"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="专业" prop="major">
                <el-input
                  placeholder="请输入专业"
                  class="form-input"
                  v-model="form.major"
                  maxlength="11"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="最高学历" prop="degree">
                <el-select
                  placeholder="请选择最高学历"
                  class="form-input"
                  v-model="form.degree"
                >
                  <el-option
                    v-for="item in eduArray"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="最近工作单位" prop="lastest_company">
                <el-input
                  placeholder="请输入最近工作单位"
                  class="form-input"
                  v-model="form.lastest_company"
                  maxlength="64"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="最近工作岗位" prop="lastest_post">
                <el-input
                  placeholder="请输入最近工作岗位"
                  class="form-input"
                  v-model="form.lastest_post"
                  maxlength="64"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="工作经验" prop="lastest_work_duration">
                <div class="flex-align">
                  <el-input-number
                    placeholder="请输入工作经验"
                    controls-position="right"
                    class="form-input"
                    :min="0"
                    v-model="form.lastest_work_duration"
                    onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    @input.native="onInput"
                  ></el-input-number>
                  <span class="tag">年</span>
                </div>
              </el-form-item>
              <el-form-item label="婚育状态" prop="marital">
                <el-select
                  placeholder="请选择婚育状态"
                  class="form-input"
                  v-model="form.marital"
                >
                  <el-option
                    v-for="item in maritalArray"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <fw-title style="margin-top:-10px" titles="简历附件"></fw-title>
            <div class="file-box">
              <fw-tableUpload
                :noToken="true"
                :add="true"
                width="780px"
                @returnMsg="attachmentBtn"
                :tableList="attachmentList"
              />
            </div>
          </el-form>
        </el-main>
        <el-footer height="auto">
          <el-row style="margin-top:10px">
            <el-col align="center">
              <el-button size="small" class="footer-btn" @click="cancelBtn"
                >取消</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="footer-btn"
                @click="saveBtn"
              >
                提交
              </el-button>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </div>
  </el-drawer>
</template>

<script>
import { isEmpty } from "@/utils/jsTool.js";
export default {
  data() {
    return {
      visible: false,
      disabledM: false,
      titles: "新增简历",
      callFn: null,
      eduArray: [
        { name: "学历不限", id: 0 },
        { name: "高中", id: 1 },
        { name: "专科", id: 2 },
        { name: "本科", id: 3 },
        { name: "硕士", id: 4 },
        { name: "博士", id: 5 },
      ], //1=高中,2=专科,3=本科,4=硕士,5=博士
      maritalArray: [
        { name: "未婚", id: 0 },
        { name: "已婚未育", id: 1 },
        { name: "已婚已育", id: 2 },
        { name: "离异", id: 3 },
      ],
      attachmentList: [],
      formRules: {
        name: [{ required: true, message: "请输入姓名" }],
        gender: [{ required: true, message: "请选择性别" }],
        mobile: [
          {
            required: true,
            validator: (rule, val, call) => {
              if (!val) {
                return call(new Error("请输入手机号码"));
              } else if (!/1[3-9]+\d{9}/.test(val)) {
                return call(new Error("请输入正确的手机号码"));
              } else {
                call();
              }
            },
          },
        ],
        birth_date: [{ required: true, message: "请选择出生日期" }],
        graduate_school: [{ required: true, message: "请输入毕业学校" }],
        graduate_date: [{ required: true, message: "请选择毕业日期" }],
        major: [{ required: true, message: "请输入专业" }],
        degree: [{ required: true, message: "请选择最高学历" }],
        lastest_company: [{ required: true, message: "请输入最近工作单位" }],
        lastest_post: [{ required: true, message: "请输入最近工作岗位" }],
        lastest_work_duration: [{ required: true, message: "请输入工作经验" }],
        marital: [{ required: true, message: "请选择婚育状态" }],
      },
      form: {
        name: "",
        gender: 1,
        mobile: "",
        birth_date: "",
        graduate_school: "",
        graduate_date: "",
        major: "", //专业
        degree: 3, //最高学历1=高中,2=专科,3=本科,4=硕士,5=博士
        lastest_company: "", //最近工作单位
        lastest_post: "", //最近工作岗位
        lastest_work_long: undefined, //最近工作时长
        marital: 0, //婚姻状况0=未婚，1=已婚未育，2=已婚已育，3=离异
        file: [], //附件
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime() - 24 * 60 * 60 * 1000;
        },
      },
    };
  },
  methods: {
    attachmentBtn(data) {
      let array = [];
      data.map((item) => {
        array.push(item.files);
      });
      this.form.file = array;
    },
    cancelBtn() {
      this.visible = false;
    },
    open(data, callFn) {
      if (!isEmpty(data)) {
        this.form = data;
        this.titles = "编辑简历";
        this.disabledM = true;
        if (data.file && data.file.length) {
          this.attachmentList = JSON.parse(JSON.stringify(data.file));
        } else {
          this.attachmentList = [];
        }
      } else {
        this.form = {
          name: "",
          gender: 1,
          mobile: "",
          birth_date: "",
          graduate_school: "",
          graduate_date: "",
          major: "", //专业
          degree: 3, //最高学历1=高中,2=专科,3=本科,4=硕士,5=博士
          lastest_company: "", //最近工作单位
          lastest_post: "", //最近工作岗位
          lastest_work_long: undefined, //最近工作时长
          marital: 0, //婚姻状况0=未婚，1=已婚未育，2=已婚已育，3=离异
          file: [], //附件
        };
        this.attachmentList = [];
      }
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.visible = true;
      this.callFn = callFn;
    },
    saveBtn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callFn && this.callFn(this.form);
        }
      });
    },
    onInput(e) {
      console.log(e.target.value, "111");
      e.target.value = e.target.value.replace(/[^\d.]/g, "");
      if (parseInt(e.target.value) > 99) {
        e.target.value = 99;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-box {
  margin: 0 30px;

  .form-padding {
    padding-left: 8px;
  }
  .file-box {
    padding-left: 12px;
  }
  .form-input {
    width: 400px;
  }
  .flex-align {
    display: flex;
    align-items: center;
    .tag {
      margin-left: 8px;
    }
  }
}
.editResume {
  height: 100vh;
  margin: 0 20px;
}
</style>
