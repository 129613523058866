<template>
  <div class="details">
    <div v-if="data">
      <p class="headers_zhaopin">
        <el-popover placement="right" width="200" v-model="visible">
          <img :src="srcs" alt="" />
          <i slot="reference" class="fa fa-qrcode icons"></i>
        </el-popover>
        <span style="margin-left: 10px">{{ data.position.name }}</span>
      </p>
      <p class="headers_zhaopin">
        <span style="color: red"
          >{{ data.position && data.position.min_salary / 1000 }}-{{
            data.position && data.position.max_salary / 1000
          }}K</span
        ><span>
          {{
            data.position.salary_type == 1
              ? "/月"
              : data.position.salary_type == 2
              ? "/年"
              : ""
          }}
        </span>
      </p>
      <p>
        {{ (data.post_type && data.post_type.name) || "--" }}
        |
        {{ getDegree(data.position && data.position.degree) }}
        |
        {{
          (data.position &&
            data.position.work_duration.substring(
              0,
              data.position.work_duration.indexOf(".")
            )) ||
          "--"
        }}年
      </p>
      <p>
        <span>工作地址：</span
        ><span style="color: #0088fe; cursor: pointer">
           {{data.work_place_province}}
          {{data.work_place_city}}
          {{data.work_place_area}}
          {{ data.work_place }}
        </span>
      </p>

      <fw-title titles="职位描述" />
      <div>
        {{ data.position.describe }}
      </div>
      <fw-title titles="职位要求" />
      <div>
        {{ data.position.other_req }}
      </div>
      <fw-title titles="薪酬福利" />
      <div>
        {{ data.position.welfare || "--" }}
      </div>
      <fw-title titles="其他" />
      <div>
        {{ data.position.remark }}
      </div>
      <!-- <fw-title titles="招聘附件" />
      <fw-tableUpload
        width="900"
        :tableList="(data.plan && data.plan.file) || []"
        :onlyLook="true"
      /> -->
    </div>
    <div>
      <div class="toudi" @click="toudi">投递简历</div>
      <div class="timer">发布时间：{{ data.updated_at || "--" }}</div>
      <div class="flex gongsi">公司信息</div>
      <div class="flex showCompany">
        <div>
          <img
            :src="data.company.company_logo"
            alt=""
            width="44px"
            height="44px"
          />
        </div>
        <div>
          <div>
            {{ data.company.name }}
          </div>
          <div>
            {{ data.company.industry || "--" }}
          </div>
          <div>
            {{ data.work_place_province || "--" }}
            {{ data.work_place_city }}
            {{ data.work_place_area }} {{ data.work_place }}
          </div>
        </div>
      </div>
      <div class="morePositin" @click="lookMorePositin">
        <i class="el-icon-view"></i><span>查看更多职位</span>
      </div>
      <div class="flex gongsi">联系人</div>
      <div class="flex showCompany">
        <div>
          <img
            :src="`https://avatar.${VUE_APP_API_URL}/staff/avatar/${data.company_id}/${data.contact_staff_id}`"
            alt=""
            width="44px"
            height="44px"
          />
        </div>
        <div>
          <div>
            {{ data.contact_by || "--" }}
          </div>
          <div>
            <p></p>
            <p>{{ data.contact_mobile || "--" }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增/编辑简历 -->
    <editResume ref="editResume" />
  </div>
</template>
<script>
import editResume from "../components/editResume.vue";
export default {
  name: "",
  components: { editResume },
  data() {
    return {
      srcs: "",
      visible: false,
      data: null,
      // eslint-disable-next-line no-undef
      VUE_APP_API_URL,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.query();
    this.getQuerys();
    // /recruit/acceptdetail?pid=216
  },
  mounted() {},
  methods: {
    getQuerys() {
      this.$axios
        .post("/ocr/generate_qrcode", {
          content:
            location.origin +
            "/_recruitH5/#/recruit/acceptdetail?pid=" +
            this.id,
          img_size: "200",
        })
        .then((res) => {
          this.srcs = res.data;
          console.log(res);
        });
    },
    lookMorePositin() {
      this.$router.push({
        path: "/hireHall/list",
        query: {
          company_id: this.data.company_id,
        },
      });
    },
    toudi() {
      this.$refs.editResume.open({}, (val) => {
        val.recruit_position_id = this.id;
        this.$axios
          .postJSON("/zhaopin/public/notoken/recruit/cv/create", val)
          .then(() => {
            this.$refs.editResume.visible = false;
            this.$message.success("添加成功");
          });
      });
    },
    getDegree(num) {
      let msg = "--";
      if (num == 0) {
        msg = "学历不限";
      } else if (num == 1) {
        msg = "高中";
      } else if (num == 2) {
        msg = "专科";
      } else if (num == 3) {
        msg = "本科";
      } else if (num == 4) {
        msg = "硕士";
      } else if (num == 5) {
        msg = "博士";
      }
      return msg;
    },
    query() {
      this.$axios
        .get("/zhaopin/public/notoken/recruit/detail", { id: this.id })
        .then((res) => {
          this.data = res.data;
          let data = JSON.parse(
            sessionStorage.getItem("platform_name_logo") || ""
          );
          document.title =
            this.data.position.name +
            "-" +
            data.value.platform.name +
            "-" +
            this.data.position.describe;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.details {
  width: 1200px;
  min-height: 90vh;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  & > div:nth-child(1) {
    flex: 1;
    overflow: hidden;
    background-color: #fff;
    padding: 32px;
    box-sizing: border-box;
  }
  & > div:nth-child(2) {
    margin-left: 18px;
    width: 293px;
    background-color: #fff;
    padding: 32px 16px;
    box-sizing: border-box;
  }
}
.headers_zhaopin {
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.toudi {
  height: 40px;
  background-color: #f74848;
  color: #fff;
  font-weight: 800;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.timer {
  color: #999;
  padding: 12px 0 15px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.flex {
  display: flex;
}
.showCompany {
  & > div:nth-child(1) {
    width: 44px;
  }
  & > div:nth-child(2) {
    margin-left: 10px;
    flex: 1;
    & > div:nth-child(1) {
      font-size: 16px;
      font-weight: 800;
      color: #333;
      line-height: 27px;
    }
    & > div:nth-child(2) {
      font-size: 12px;
      color: #666;
    }
  }
}
.morePositin {
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  i {
    color: #f74848;
    margin-right: 5px;
  }
  span {
    cursor: pointer;
  }
}
.gongsi {
  margin-bottom: 16px;
  font-weight: 800;
}
.icons {
  cursor: pointer;
  color: #0088fe;
}
</style>
